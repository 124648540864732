<template>
    <div>
        <modal
            class="new-modal-default"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="1000"
            :height="400"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important;">
                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/parrot.svg" style="height: 10rem !important" />
                <div style="font-size: 16px" class="mt-8">
                    <div class="fw-600 mb-4" style="font-size: 20px;">Success</div>
                    Copied Link Successfully
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: ["modal_name"],
}
</script>